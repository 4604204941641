<template>
  <ul class="footer-menu-list__wrapper">
    <li v-for="item in items" :key="item.title" class="footer-menu-list__item">
      <a-link
        :to="item.Url"
        :trailing-slash="$helper.isLinkWithTrailingSlash(item.Url)"
        :open-in-new-tab="item.OpenInNewTab"
        :directive="item.UrlDirectives"
        class="footer-menu-list__link font-sans"
      >
        {{ item.Name }}
        <div v-if="item.Media" class="footer-menu-list__logo-wrapper">
          <a-image
            :image="item.Media"
            :aspect-ratio="aspectRatios.footerRightColumnLogo"
            class="footer-menu-list__item-logo"
          />
        </div>
      </a-link>
    </li>
  </ul>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'

import { aspectRatios } from 'enums/aspectRatios'

export default {
  name: 'FooterMenuList',
  props: {
    items: propValidator([PROP_TYPES.ARRAY])
  },
  data() {
    return { aspectRatios }
  }
}
</script>

<style lang="scss" scoped>
.footer-menu-list__wrapper {
  .footer-menu-list__link {
    display: inline-flex;
    align-items: center;
    color: $c--white;
    line-height: 29px;

    &:hover {
      color: $c--main;
    }
  }

  .footer-menu-list__logo-wrapper {
    width: 49px;
    height: 21px;
    margin-left: 6px;
  }

  .footer-menu-list__item-logo {
    display: inline-flex;
  }
}
</style>
